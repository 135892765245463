const COMPANY_NAME = "Tipy Family";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title:
        "Agência nº 1 em Imóveis Transaccionados a nível nacional\nAgência DOUBLE CENTURION Office",
      subTitle: "SAIBA O VALOR DA SUA CASA",
    },
    disclaimer:
      "Ao subscrever os serviços disponibilizados por esta plataforma, e consequente consentimento, a Tipy Family procede à recolha e ao tratamento dos dados pessoais necessários à prestação dos serviços de mediação imobiliária e a fornecer informação sobre bens imobiliários e oportunidades de negócios e irá transmitir os dados recolhidos aos consultores imobiliários que compõem a sua rede, exclusivamente para os fins mencionados.",
    notice:
      "Cada agência é jurídica e financeiramente independente Tipy Family - Soc. de Mediação imoliária Lda. | AMI 2482 | FGP LX. Lda. | AMI 16845 | FGSADO Lda. | AMI 17175 | FGM&C LDA | AMI 18194",
  },
  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title:
        "Nº1 agency in sold properties at national level\nDOUBLE CENTURION Office Agency",
      subTitle: "KNOW VALUE YOUR PROPERTY",
    },
    disclaimer:
      "By subscribing and consenting to the services provided by this platform, Tipy Family Group collects and processes all the personal data necessary to provide real estate services, information about the real estate market and business opportunities. Furthermore, it will transmit the this data to real estate consultants that make up its network, exclusively for the aforementioned purposes mentioned.",
    notice:
      "Each agency is legally and financially independent Tipy Family - Soc. de Mediação imoliária Lda. | AMI 2482 | FGP LX. Lda. | AMI 16845 | FGSADO Lda. | AMI 17175 | FGM&C LDA | AMI 18194",
  },
};
